/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
( function( app ) {
  const COMPONENT = {
    init: function() {
      $( '.js-init-video' ).magnificPopup( {
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        callbacks: {
          open: function() {
            $( 'video' ).trigger( 'pause' );
          },
          close: function() {
            $( 'video' ).trigger( 'play' );
          },
        },
      } );

      $( '.primary-link' ).on( 'toggleAfter', ( event ) => {
        if ( $( '.nav__overflow' ).hasClass( 'overflow-y-auto' ) ) {
          $( '.nav__overflow' ).removeClass( 'overflow-y-auto' );
        } else {
          $( '.nav__overflow' ).addClass( 'overflow-y-auto' );
        }
      } );

      $( '.search-btn' ).on( 'toggleAfter', ( event ) => {
        $( '.search-panel' ).slideToggle();
      } );

      $( '.primary-btn' ).each( function() {
        $( this ).append( '<svg class="icon icon-chevron-right"><use xlink:href="#icon-chevron-right"></use></svg>' );
      } );

      $( '.secondary-btn, .secondary-btn-gold' ).each( function() {
        $( this ).append( '<svg class="icon icon-right-arrow"><use xlink:href="#icon-right-arrow"></use></svg>' );
      } );

      $( document ).on( 'updateLargeMediaQuery', ( event ) => {
        /*
         * Remove any inline display values when the screen changes
         * between medium and Large desktop state. This allows the default
         * stylings to kick in and prevent any weird "half mobile half desktop"
         * nav display states that sometimes occur while resizing the browser
         * Also remove any active is-open classes from the toggle and nav to reset
         * its state when switching between screen sizes
         */
        if ( $( '.is-open' ).length !== 0 ) {
          $( '.is-open' ).each( function() {
            $( this ).removeClass( 'is-open' );
          } );
        }
      } );

      // trigger next button on radio page radio click
      $( document ).on( 'click', '.llgq-current-page input[type="radio"]', function() {
        if ( $( '.llgq-current-page' ).hasClass( 'llgq-radio-page' ) ) {
          $( '.llgq-current-page .gform_next_button' ).trigger( 'click' );
        }
      } );

      const pages = document.querySelectorAll( '.gform_page' );

      // Hide footer on inital quoter load
      // $( document ).on( 'gform_page_loaded', function( event, formId, currentPage ) {
      //   window.easyToggleState();
      //   const curFormPage = $( '#gform_page_' + formId + '_' + currentPage );
      //   const subButton = curFormPage.find( '.form__submit-btn' );


      //   // Add custom submit and remove custom skip button if it is the last page
      //   if ( subButton.length === 0 ) {
      //     $( '.gform_page_footer' ).addClass( 'sr-only' );
      //   } else {
      //     $( '.llgq_section_wrapper_2' ).addClass( 'last_page' );
      //     $( '.llgq_section_wrapper_2' ).addClass( 'content-center' );
      //   }
      // } );
    },
    finalize: function() {
    },
  };

  app.registerComponent( 'common', COMPONENT );
} )( app );
