/**
* Image Accordion JS
* -----------------------------------------------------------------------------
*
* All the JS for the Image Accordion component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'image-accordion',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      // gsap.registerPlugin( ScrollTrigger );
      $( '.image-accordion__item-status, .image-accordion__item-title' ).click( function() {
        if ( $( this ).closest( '.image-accordion__item' ).hasClass( 'is-open' ) ) {
          $( this ).closest( '.image-accordion__item' ).find( '.image-accordion__item-answer' ).slideUp();
          $( this ).closest( '.image-accordion__item' ).removeClass( 'is-open' );
          $( this ).closest( '.image-accordion__item .accordion-trigger' ).attr( 'aria-expanded', false );
        } else {
          $( '.image-accordion__item.is-open .image-accordion__item-answer' ).slideUp();
          $( '.image-accordion__item' ).removeClass( 'is-open' ).not( $( this ).closest( '.image-accordion__item' ) ).find( '.accordion-trigger' ).attr( 'aria-expanded', false );
          $( this ).closest( '.image-accordion__item' ).find( '.image-accordion__item-answer' ).slideDown();
          $( this ).closest( '.image-accordion__item' ).find( '.accordion-trigger' ).attr( 'aria-expanded', true );
          $( this ).closest( '.image-accordion__item' ).addClass( 'is-open' );
        }
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'image-accordion', COMPONENT );
} )( app );
