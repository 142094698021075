/**
* Content List JS
* -----------------------------------------------------------------------------
*
* All the JS for the Content List component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'content-list',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      gsap.registerPlugin( ScrollTrigger );
      // Set up timeline for cards
      const tl = gsap.timeline( {
        scrollTrigger: {
          trigger: '.content-list__list',
          start: 'top 90%',
          end: 'bottom center',
          scrub: true,
          // markers: true,
          toggleActions: 'play reverse play reverse',
        },
      } );

      // Add each card to timeline with its own animation
      gsap.utils.toArray( '.content-list__list-item' ).forEach( ( elem ) => {
        tl
            .to( elem, {opacity: 1, duration: 0.3} );
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'content-list', COMPONENT );
} )( app );
