/**
* Testimonial Slider JS
* -----------------------------------------------------------------------------
*
* All the JS for the Testimonial Slider component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'testimonial-slider',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      // gsap.registerPlugin( ScrollTrigger );
      $( '.testimonial-slider' ).each( function( key, component ) {
        $( component ).find( '.testimonial-slider__slider' ).slick( {
          dots: false,
          arrows: true,
          // appendArrows: $( '.testimonial-slider__slider-nav' ),
          prevArrow: $( component ).find( '.prev-slide' )[0],
          nextArrow: $( component ).find( '.next-slide' )[0],
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],
        } );
        $tallestCard = 0;
        $( '.testimonial-slider__slide' ).each( function() {
          if ( $( this ).height() > $tallestCard ) {
            $tallestCard = $( this ).height();
          }
        } );
        $( '.testimonial-slider__slide' ).each( function() {
          $( this ).height( $tallestCard );
        } );
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'testimonial-slider', COMPONENT );
} )( app );
