/**
* Tab Link JS
* -----------------------------------------------------------------------------
*
* All the JS for the Tab Link component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
gsap.registerPlugin( ScrollTrigger, ScrollToPlugin );
( function( app ) {
  const COMPONENT = {

    className: 'tab-link',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      let buttonClick = false;
      const items = gsap.utils.toArray( '.tab-link__stepper-child' );
      ScrollTrigger.matchMedia( {

        // desktop
        '(min-width: 1024px)': function() {
          // mouse scorll trigger
          items.forEach( ( elem, index ) => {
            let start = elem;

            if ( index === 0 ) {
              start = '.tab-link';
            }

            ScrollTrigger.create( {
              trigger: start,
              endTrigger: elem,
              id: 'vertical-tab_scroll',
              start: 'top 20%',
              end: 'bottom 20%',
              // markers: true,
              toggleActions: 'play reverse none reverse',
              toggleClass: {targets: `#step-${index}`, className: 'is-selected'},
              onLeave: ( {direction} ) => {
                if ( index === items.length-1 ) {
                  $( `#step-${index}` ).addClass( 'is-selected' );
                }
              },
              onLeaveBack: () => {
                if ( index === 0 ) {
                  $( `#step-${index}` ).addClass( 'is-selected' );
                }
              },
            } );
          } );

          const scrollToTab = ( event ) => {
            event.preventDefault();
            // get all scroll triggers to disable ones that toggle the is-selected class
            const scrolls = ScrollTrigger.getAll( );

            // height of nav for offset
            const navHeight = $( '.navbar' ).height();
            const tab = $( event.target ).attr( 'data' );
            // disable (mouse scroll) scrolltrigger if button is clicked
            scrolls.forEach( ( ins ) => ins.disable() );
            gsap.to( window, {
              duration: 1,
              scrollTo: {y: `#${tab}`, offsetY: navHeight},
              onComplete: () => {
                // when it's finished, re-enable (mouse) scrolltrigger
                scrolls.forEach( ( ins ) => ins.enable() );
                buttonClick = false;
              },
            } );
          };

          $( '.tab-link__stepper-nav-item' ).on( 'click', ( event ) => {
            if ( !buttonClick ) {
              buttonClick = true;
              isSelected( event, scrollToTab );
            }
          } );

          // Adds is-selected and runs srollToTab function on click
          const isSelected = ( event, scrollToTab ) => {
            event.preventDefault();
            const button = event.target;
            const prev = $( '.tab-link__stepper-nav' ).find( '.is-selected' )[0];

            if ( !$( event.target ).hasClass( 'is-selected' ) || !prev ) {
              scrollToTab( event );
            }
          };

          function goToSection( i, anim ) {
            const navHeight = $( '.navbar' ).height();
            const panelId = $( i ).attr( 'id' );
            gsap.to( window, {
              scrollTo: {y: `#${ panelId }`, offsetY: navHeight},
              duration: 1,
            } );

            if ( anim ) {
              anim.restart();
            }
          }

          gsap.utils.toArray( '.tab-link__stepper-child' ).forEach( ( panel, i ) => {
            ScrollTrigger.create( {
              trigger: panel,
              // markers: true,
              onEnter: () => {
                // prevents the mouse scrolltrigger from interrupting this scrolltrigger
                // if the button is NOT clicked, do this.
                if ( !buttonClick ) {
                  goToSection( panel );
                }
              },
            } );

            ScrollTrigger.create( {
              trigger: panel,
              start: 'bottom 20%',
              end: 'top 20%',
              // markers: true,
              onEnterBack: () => {
                if ( !buttonClick ) {
                  goToSection( panel );
                }
              },
            } );
          } );
        },
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'tab-link', COMPONENT );
} )( app );
