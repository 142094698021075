/**
* Mini Tab Slider JS
* -----------------------------------------------------------------------------
*
* All the JS for the Mini Tab Slider component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'mini-tab-slider',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      // gsap.registerPlugin( ScrollTrigger );
      $( '.mini-tab-slider' ).each( function( key, component ) {
        // Get how many slides exist
        const count = parseInt( $( component ).attr( 'data-slide-count' ) );
        let toShow = 2;
        if ( count === 1 ) {
          toShow = 0;
        } else if ( count == 2 ) {
          toShow = 1;
        } else if ( count === 3 ) {
          toShow = 2;
        } else {
          toShow = 3;
        }


        $( component ).find( '.mini-tab-slider__main-slider' ).slick( {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          asNavFor: '.mini-tab-slider__slider-nav',
          nextArrow: '.mini-main-slider__next',
          prevArrow: null,
          // fade: true,

        } ).on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {
          console.log( $( component ).find( '.slick-slide .main-slider__image, .slick-slide .mini-main-slider__count.opcaity-0' ) );
          $( component ).find( '.slick-slide .main-slider__image, .slick-slide .mini-main-slider__count' ).removeClass( 'opacity-0' );
          // $( component ).find( '.slick-active .main-slider__image, .slick-active .mini-main-slider__count' ).addClass( 'opacity-0' );
        }
        );

        if ( toShow !== 0 ) {
          $( component ).find( '.mini-tab-slider__slider-nav' ).slick( {
            slidesToShow: toShow,
            slidesToScroll: 1,
            asNavFor: '.mini-tab-slider__main-slider',
            dots: false,
            arrows: false,
          } );
        }

        $( component ).find( '.mini-tab-slider__slider-nav' ).on( 'click', '.mini-slider-nav__slide', function() {
          const slideNo = $( this ).attr( 'data-nav-slide' );
          $( component ).find( '.mini-tab-slider__main-slider' ).slick( 'slickGoTo', slideNo );
        } );
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'mini-tab-slider', COMPONENT );
} )( app );
