/**
* Two Column List JS
* -----------------------------------------------------------------------------
*
* All the JS for the Two Column List component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'two-column-list',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      // gsap.registerPlugin( ScrollTrigger );
      function reveal( elements ) {
        gsap.to( elements,
            {
              clipPath: 'inset(0% 0% 0% 0%)',
              duration: 1.5,
              ease: '0, 0, 0.58, 1.0',
              stagger: 0.2, // delay e.g 0.2s
            },
        );
      }

      function createAnimation( elements, startAnimationObj, animationFunction, start = 'top 90%' ) {
        if ( ( typeof elements === 'string' && document.querySelectorAll( elements ).length > 0 ) || ( typeof elements === 'object' && elements.length > 0 ) ) {
          gsap.set( elements, startAnimationObj );
          const triggers = ScrollTrigger.batch( elements, {
            toggleClass: 'js-animated',
            once: true,
            start: start,
            onEnter: ( batch ) => {
              animationFunction( batch );
            },
          } );

          ScrollTrigger.addEventListener( 'refreshInit', () => {
            triggers.forEach( ( trigger ) => {
              if ( trigger.progress > 0 ) {
                animationFunction( trigger.trigger );
              }
            } );
          } );
        }
      }

      const drawLineClasses = '.js-draw-line:not(.js-ignore)';
      createAnimation( drawLineClasses, {clipPath: 'inset(0 100% 0 0)'}, reveal );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'two-column-list', COMPONENT );
} )( app );
