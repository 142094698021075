/**
* Card Slider JS
* -----------------------------------------------------------------------------
*
* All the JS for the Card Slider component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'card-slider',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      // gsap.registerPlugin( ScrollTrigger );
      $( '.card-slider' ).each( function( key, component ) {
        $( component ).find( '.card-slider__slider' ).on( 'init', function( event, slick ) {
          const active = $( component ).find( '.card-slider__slider .slick-slide.slick-active' );
          const progress = ( active.length / slick.slideCount ) * 100;
          $( component ).find( '.slider-progress .progress' ).width( progress + '%' );
        } );
        $( component ).find( '.card-slider__slider' ).slick( {
          dots: false,
          arrows: true,
          prevArrow: $( component ).find( '.prev-slide' )[0],
          nextArrow: $( component ).find( '.next-slide' )[0],
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
              },
            },
          ],
        } );

        $( component ).find( '.card-slider__card' ).each( function( ) {
          const info = $( this ).find( '.card-slider__card-info' );
          const height = $( this ).find( '.card-slider__card-link' ).outerHeight();
          info.css( '--link-height', height );
        } );

        $( component ).find( '.card-slider__slider' ).on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {
          if ( nextSlide === 0 ) {
            const active = $( component ).find( '.card-slider__slider .slick-slide.slick-active' );
            const progress = ( active.length / slick.slideCount ) * 100;
            $( component ).find( '.slider-progress .progress' ).width( progress + '%' );
          } else {
            const progress = ( ( nextSlide + 1 ) / slick.slideCount ) * 100;
            $( component ).find( '.slider-progress .progress' ).width( progress + '%' );
          }
        } );

        // CLICKABLE CARDS IN NAV
        const cards = $( component ).find( '.card-slider__card' );

        function cardLink( cards ) {
          cards.each( function( index, box ) {
            const link = box.querySelector( 'a' );
            if ( link ) {
              const url = link.getAttribute( 'href' );
              box.addEventListener( 'click', function() {
                location.href = url;
                link.preventDefault;
              } );
            }
          } );
        }

        if ( cards.length > 0 ) {
          cardLink( cards );
        }
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'card-slider', COMPONENT );
} )( app );
